import focus from '@alpinejs/focus';
// @ts-expect-error
import ui from '@alpinejs/ui';
import Alpine from 'alpinejs';
import 'vite/modulepreload-polyfill';

onReady(() => {
  Alpine.plugin(focus);
  Alpine.plugin(ui);
  Alpine.start();
});
